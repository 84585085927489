import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ColumnsComponent } from 'app/pages/miscellaneous/columns/columns.component';

@Component({
  selector: 'ngx-warehouse-teacher-orders',
  templateUrl: './teacher-orders.component.html',
  styleUrls: ['./teacher-orders.component.scss']
})
export class TeacherOrdersComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() source: any;
  @Input() selectionType: string;
  @Output() selected: EventEmitter<any> = new EventEmitter();

  temp = [];
  filtered = false;
  rowSelected = false;
  loading = true;
  selectedOrders: Array<any> = [];
  selection: any = { type: 'single' };
  checkboxable = false;

  columns: any = {
    id: {
      name: 'ID',
      width: 110,
      visible: true
    },
    orderDate: {
      name: 'Order Date',
      width: 100,
      visible: false
    },
    releaseDate: {
      name: 'Release Date',
      width: 100,
      visible: true
    },
    teacher: {
      name: 'Teacher',
      width: 200,
      visible: true
    },
    school: {
      name: 'School',
      width: 300,
      visible: true
    },
    city: {
      name: 'City',
      width: 200,
      visible: false
    },
    status: {
      name: 'Status',
      width: 155,
      visible: true,
    },
    state: {
      name: 'State',
      width: 190,
      visible: true
    }
  };

  constructor(private _dialogService: NbDialogService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.temp = changes.source.currentValue;
    if (changes.selectionType) {
      this.selection.type = changes.selectionType.currentValue;
      if (this.selection.type === 'checkbox') {
        this.checkboxable = true;
      }
    }

    this.loading = false;
  }

  getRowClass(row: any) {
    if (row.state) {
      return row.state.replace(' ', '-').toLowerCase();
    }

    return row.status.replace(' ', '-').toLowerCase();
  }

  filterInput(key: string, event: any) {
    const value = event.target.value.toLowerCase();
    const temp = this.temp.filter(d => {
      return d[key].toLowerCase().indexOf(value) !== -1 || !value;
    });

    this.source = temp;
    this.filtered = true;
  }

  filterSelect(key: string, value: string) {
    const temp = this.temp.filter(d => {
      if (!value) return true;

      if (d[key]) {
        return d[key].indexOf(value) !== -1 || !value;
      }
    });

    this.source = temp;
    this.filtered = true;
  }

  filterDate(date: string) {
    const temp = this.temp.filter(d => {
      if (!date) return true;

      if (d.orderDates) {
        return this.getDate(date) === this.getDate(d.orderDates.release_date);
      }
    });

    this.source = temp;
    this.filtered = true;
  }

  dateChange(event, name) {
    this.filterDate(event.target.value);
  }

  getDate(date: string) {
    const d = new Date(date);
    return `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}`;
  }

  selectOrder(event: any) {
    if (event.selected) {
      this.rowSelected = event.selected.length;
      this.selectedOrders = event.selected;
      this.selected.emit(this.selectedOrders);
    }
  }

  columnSelector() {
    const columns: Array<any> = Object.values(this.columns);
    this._dialogService.open(ColumnsComponent, {
      context: {
        columns: columns
      }
    });
  }

}
