import { ModuleWithProviders, NgModule, Optional, SkipSelf, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbAuthModule,
  NbPasswordAuthStrategy,
  NbAuthJWTToken,
  NbTokenService
} from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { DataModule } from './data/data.module';
import { AnalyticsService } from './utils/analytics.service';

// import { AngularFireModule } from '@angular/fire';
// import { AngularFireAuthModule } from '@angular/fire/auth';
// import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { AuthModule } from './auth/auth.module';
import { AuthGuard } from './auth/auth.guard';
import { NbLayoutModule, NbCardModule, NbDatepickerModule } from '@nebular/theme';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { DisplayService } from './utils/display.service';
import { ComponentsModule as WarehouseComponentsModule } from '../pages/warehouse/components/components.module';

// const socialLinks = [
//   {
//     url: 'https://github.com/akveo/nebular',
//     target: '_blank',
//     icon: 'socicon-github',
//   },
//   {
//     url: 'https://www.facebook.com/akveo/',
//     target: '_blank',
//     icon: 'socicon-facebook',
//   },
//   {
//     url: 'https://twitter.com/akveo_inc',
//     target: '_blank',
//     icon: 'socicon-twitter',
//   },
// ];

@Injectable()
export class NbSimpleRoleProvider extends NbRoleProvider {

  constructor(private _tokenService: NbTokenService) {
    super();
  }

  getRole() {
    return this._tokenService.tokenChange()
      .pipe(
        map((token: NbAuthJWTToken) => {
          const roles: Array<string> = [
            // HARDCODED???
            'guest',
            'admin',
            'manager',
            'office',
            'sales',
            'warehouse'
          ];

          return token.isValid()
            ? roles[token.getPayload()['user']['roleId']] : 'guest';
        })
      );
  }
}

export const NB_CORE_PROVIDERS = [
  ...DataModule.forRoot().providers,
  ...NbAuthModule.forRoot({

    strategies: [
      NbPasswordAuthStrategy.setup({
        name: 'email',
        baseEndpoint: `${environment.api.endpoint}`,
        login: {
          endpoint: '/Staff/login',
          redirect: {
            success: '/pages/dashboard'
          }
        },
        logout: {
          endpoint: '/Staff/logout',
          method: 'post'
        },
        requestPass: {
          endpoint: '/Staff/reset'
        },
        resetPass: {
          endpoint: '/Staff/reset-password',
          method: 'post',
          resetPasswordTokenKey: 'access_token'
        },
        token: {
          class: NbAuthJWTToken,
          key: 'accessToken'
        }
      })
    ]
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      guest: {},
      warehouse: {},
      sales: {
        view: ['school', 'directory', 'sales']
      },
      office: {
        view: [
          'directory',
          'orders',
          'dashboard',
          'school',
          'teacher',
          'warehouse',
          'product'
        ],
        create: ['school', 'teacher-order'],
        edit: ['school', 'product']
      },
      manager: {
        parent: 'office',
        view: ['sales']
      },
      admin: {
        view: '*',
        create: '*',
        edit: '*',
        remove: '*',
      },
    },
  }).providers,
  { provide: NbRoleProvider, useClass: NbSimpleRoleProvider },
  AnalyticsService,
  DisplayService,
  AuthGuard
];

@NgModule({
  imports: [
    CommonModule,
    AuthModule,
    // JwtModule.forRoot({
    //   config: {
    //     tokenGetter: () => {
    //       const token = JSON.parse(localStorage.getItem('auth_app_token'));
    //       return token.value;
    //     },
    //     whitelistedDomains: ['localhost:3000'],
    //     blacklistedRoutes: [/localhost:3000\/api\/Staff\/login/]
    //   }
    // }),
    // AngularFireModule.initializeApp(environment.firebase),
    // AngularFireAuthModule,
    // AngularFirestoreModule,
    NbLayoutModule,
    NbCardModule,
    NbDatepickerModule.forRoot(),
    // NbDateFnsDateModule.forRoot({ format: 'yyyy-MM-dd' }),
    WarehouseComponentsModule
  ],
  exports: [
    NbAuthModule
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
