export const Roles: Array<string> = [
    'Guest',
    'Admin',
    'Manager',
    'Office',
    'Sales',
    'Warehouse'
];

export class User {
    id: number;
    username: string;
    firstname: string;
    lastname: string;
    email: string;
    name: string;
    role: string;

    constructor(user?: any) {
        this.name = (user.firstname && user.lastname)
            ? `${user.firstname || ''} ${user.lastname || ''}` : null;

        this.email = user.email;
        this.role = (user.roleId) ? Roles[user.roleId] : null;
        this.username = (user.username) ? user.username : null;
    }
}

export class Staff extends User {
    roleId?: number;
}
