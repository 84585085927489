import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './@core/auth/auth.guard';
import { NbAuthComponent, NbLoginComponent, NbRequestPasswordComponent } from '@nebular/auth';
import { LogoutComponent } from './@core/auth/logout/logout.component';
import { LoginComponent } from './@core/auth/login/login.component';
import { ResetComponent } from '@core/auth/reset/reset.component';
import { PrintLayoutComponent } from './pages/warehouse/components/print-layout/print-layout.component';
import { DocumentsComponent } from './pages/warehouse/components/documents/documents.component';

const routes: Routes = [
  {
    path: 'pages',
    canActivate: [AuthGuard],
    loadChildren: 'app/pages/pages.module#PagesModule'
  },
  {
    path: 'print',
    outlet: 'print',
    component: PrintLayoutComponent,
    children: [
      { path: 'documents/:type/:item/:ids', component: DocumentsComponent}
    ]
  },
  {
    path: 'auth',
    component: NbAuthComponent,
    children: [
      { path: '', component: NbLoginComponent },
      { path: 'login', component: LoginComponent },
      { path: 'logout', component: LogoutComponent },
      { path: 'request-password', component: NbRequestPasswordComponent },
      { path: 'reset-password', component: ResetComponent }
    ]
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' }
];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
