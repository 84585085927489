import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-print-layout',
  templateUrl: './print-layout.component.html',
  styleUrls: ['./print-layout.component.scss']
})
export class PrintLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
