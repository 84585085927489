import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CatalogService } from '@services/catalog.service';
import { NbSidebarService } from '@nebular/theme';

@Component({
  selector: 'ngx-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss']
})
export class CatalogComponent implements OnInit {
  @Output() userRowSelect: EventEmitter<any> = new EventEmitter();
  sourceProducts: any;
  sourceSettings = {
    actions: {
      add: false,
      edit: false,
      delete: false
    },
    pager: {
      perPage: 13
    },
    columns: {
      sku: {
        title: 'SKU',
        width: '150px'
      },
      name: {
        title: 'Product Name'
      },
      retail: {
        title: 'Retail',
        width: '75px',
        filter: false
      }
    }
  };

  constructor(
    private _sidebarService: NbSidebarService,
    private _catalogService: CatalogService) { }

  ngOnInit() {
    this._catalogService.getProducts()
      .then(products => this.sourceProducts = products);
  }

  toggleSidebar() {
    this._sidebarService.toggle(false, 'catalog-sidebar');
  }

  addProduct(ev: any) {
    this.userRowSelect.emit(ev);
  }
}
