import { Component, OnInit } from '@angular/core';
import { NbLoginComponent} from '@nebular/auth';
import { environment } from '@environments/environment';
// import { getDeepFromObject } from '@nebular/auth/helpers';
// import { AuthService } from '../auth.service';
// import { Router } from '@angular/router';

@Component({
  selector: 'ngx-login',
  styleUrls: ['./login.component.scss'],
  templateUrl: './login.component.html'
})
export class LoginComponent extends NbLoginComponent implements OnInit {
  environment: any = {};

  ngOnInit() {
    this.environment = environment;
  }

  // showMessages: any = {};
  // submitted: boolean = false;

  // constructor(
  //   @Inject(NB_AUTH_OPTIONS) protected config = {},
  //   private _auth: NbAuthService,
  //   private _router: Router,
  //   private _cd: ChangeDetectorRef,
  //   private _authService: AuthService,) {

  //   super(_auth, config, _cd, _router);
  //   this.showMessages = this.getConfigValue('forms.login.showMessages');
  // }

  // login() {
  //   this.submitted = true;

  //   this._authService.signIn(this.user)
  //     .then(() => {
  //       this.submitted = false;
  //       this.messages = ['Successfully Logged in.'];

  //       this._router.navigateByUrl('/pages/school');
  //     })
  //     .catch(err => {
  //       this.submitted = false;
  //       this.errors = [err.message];
  //     });
  // }

  // getConfigValue(key: string): any {
  //   return getDeepFromObject(this.config, key, null);
  // }
}
