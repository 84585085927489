import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';
import { OrderDate } from 'app/pages/teacher/order/order';

const YEAR = 2020;

@Component({
  selector: 'ngx-cover-sheet',
  templateUrl: './cover-sheet.component.html',
  styleUrls: ['./cover-sheet.component.scss']
})
export class CoverSheetComponent implements OnInit {
  @Input() data: any;
  dates: OrderDate;

  constructor() { }

  ngOnInit() {
    this.dates = new OrderDate(
      _.find(this.data.order.teacher.school.teacherOrderDates, { year: YEAR }));
  }

}
