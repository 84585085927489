import { Component, OnInit } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { NbAccessChecker } from '@nebular/security';
import * as _ from 'lodash';

@Component({
  selector: 'ngx-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  settingsMenu = [
    {
      title: 'zOffice',
      group: true
    },
    {
      title: 'Staff',
      icon: 'fas fa-user-cog',
      link: '/pages/misc/staff',
      data: {
        permission: 'view',
        resource: 'admin'
      }
    },
    {
      title: 'About',
      icon: 'fas fa-info',
      link: '/pages/misc/info'
    }
  ];

  constructor(private _accessChecker: NbAccessChecker) { }

  ngOnInit() {
    _.forEach(this.settingsMenu, (item) => this._authMenuItem(item));
  }

  _authMenuItem(menuItem: NbMenuItem) {
    if (menuItem.data && menuItem.data['permission'] && menuItem.data['resource']) {
      this._accessChecker.isGranted(menuItem.data['permission'], menuItem.data['resource'])
        .subscribe(granted => menuItem.hidden = !granted);
    } else {
      menuItem.hidden = menuItem.hidden;
    }
  }
}
