import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '@core/auth/auth.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'ngx-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  form: FormGroup;

  constructor(
    private _fb: FormBuilder,
    private _authService: AuthService,
    private _toastService: NbToastrService,
    private dialogRef: NbDialogRef<any>) { }

  ngOnInit() {
    this.form = this._fb.group({
      password: [null, Validators.compose([Validators.required, Validators.minLength(4)])],
      repeat: [null, Validators.compose([Validators.required, Validators.minLength(4)])]
    });
  }

  save(form) {
    this._authService.changePassword({ newPassword: form.password })
      .then(() => {
        this._toastService.success('Password Changed', 'Success');
        this.dialogRef.close();
      })
      .catch(err => {
        console.error(err);
        this._toastService.danger(err.message, 'Error updating passsword', {
          duration: environment.toast.duration.error });
      });
  }

  cancel() {
    this.dialogRef.close();
  }

}
