import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() accent: string;

  constructor(protected dialogRef: NbDialogRef<any>) { }

  ngOnInit() {
  }

  close(response: boolean) {
    this.dialogRef.close(response);
  }

}
