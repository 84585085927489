import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.scss']
})
export class CancelComponent implements OnInit {
  @Input() header: string;
  returnProducts = false;

  constructor(protected dialogRef: NbDialogRef<any>) { }

  ngOnInit() {
  }

  close(response: boolean) {
    this.dialogRef.close({
      cancel: response,
      returnProducts: this.returnProducts
    });
  }

}
