import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  isPrinting = false;

  constructor(private _router: Router) { }

  printDocument(name: string, type: string, item: string, data: number[]) {
    this.isPrinting = true;
    this._router.navigate(['/', {
      outlets: { print: ['print', name, type, item, data.join(',')]}
    }],
    { queryParams: { action: 'print' } });
  }

  onDataReady() {
    setTimeout(() => {
      window.print();
      this.isPrinting = false;
      this._router.navigate([{ outlets: { print: null }}]);
    });
  }
}
