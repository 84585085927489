import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { NbTokenService, NbAuthJWTToken } from '@nebular/auth';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WrkTeacherOrderService {

  api = `${environment.api.endpoint}`;

  constructor(
    private _http: HttpClient,
    private _tokenService: NbTokenService) { }

  async saveOrder(order: any) {
    const user: any = await this._getUser();
    const data = {
      id: order.id,
      type: 'draft',
      staff_id: user.id
    };

    return this._http.post(`${this.api}/wrkTeacherOrders/save`, { data: data })
      .toPromise();
  }

  async createOrder(order: any) {
    const user: any = await this._getUser();

    const data = {
      type: 'draft',
      staff_id: user.id
    };

    return this._http.post(`${this.api}/wrkTeacherOrders`, data)
      .toPromise();
  }

  async updateOrder(order: any) {
    const user: any = await this._getUser();

    const data = {
      id: order.id,
      type: 'draft',
      staff_id: user.id
    };

    return this._http.put(`${this.api}/wrkTeacherOrders`, data)
      .toPromise();
  }

  async deleteOrder(id: number) {
    return this._http.delete(`${this.api}/wrkTeacherOrders/${id}`)
      .toPromise();
  }

  async _getUser() {
    let user = {};

    this._tokenService.get()
      .pipe(map((token: NbAuthJWTToken) => token.getPayload()))
      .subscribe(token => user = token.user);

    return await user;
  }
}
