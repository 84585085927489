import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { AuthService } from '@core/auth/auth.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'ngx-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {
  form: FormGroup;
  @Input() userId: number;

  constructor(
    private _authService: AuthService,
    private _toastService: NbToastrService,
    private _dialogRef: NbDialogRef<any>,
    private _fb: FormBuilder) { }

  ngOnInit() {
    this.form = this._fb.group({
      password: [null, [Validators.required, Validators.minLength(4)]],
      repeat: [null, [Validators.required, Validators.minLength(4)]]
    });
  }

  save(form: any) {
    this._authService.changeStaffPassword({
      userId: this.userId,
      password: form.password
    })
      .then(res => {
        this.close();
        this._toastService.success('Password has been updated', 'Success');
      })
      .catch(err => {
        console.error(err);
        const error = err.error.error.message;
        this._toastService.danger(error, 'Error updating password', {
          duration: environment.toast.duration.error });
      });
  }

  close() {
    this._dialogRef.close();
  }

}
