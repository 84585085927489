import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @Input() tag: string;
  @Input() results: Array<any>;

  constructor(protected dialogRef: NbDialogRef<any>) { }

  ngOnInit() {
  }

  selectResult(result: any) {
    this.dialogRef.close(result);
  }

}
