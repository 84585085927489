import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';

export class Province {
    id: number;
    code: string;
    name: string;
}

@Injectable()
export class ConstantsService {
    private provinceArray: Array<Province>;

    private provinces = [
        { id: 1, code: 'BC', name: 'British Columbia' },
        { id: 2, code: 'AB', name: 'Alberta' },
        { id: 3, code: 'SK', name: 'Saskatchewan' },
        { id: 4, code: 'MB', name: 'Manitoba' },
        { id: 5, code: 'ON', name: 'Ontario' },
        { id: 6, code: 'NS', name: 'Nova Scotia' },
        { id: 7, code: 'NL', name: 'Newfoundland & Labrador' },
        { id: 8, code: 'SP', name: 'Sample Province' },
        { id: 9, code: 'NB', name: 'New Brunswick' },
        { id: 10, code: 'NT', name: 'Northwest Territories' },
        { id: 11, code: 'NU', name: 'Nunavut' },
        { id: 12, code: 'PE', name: 'Prince Edward Island' },
        { id: 13, code: 'QC', name: 'Quebec' },
        { id: 14, code: 'YT', name: 'Yukon Territory' },
    ];

    constructor() {
        this.provinceArray = Object.values(this.provinces);
    }

    getProvinces(): Observable<any> {
        return observableOf(this.provinces);
    }

    getProvinceArray(): Observable<Array<Province>> {
        return observableOf(this.provinceArray);
    }
}
