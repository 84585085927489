import { Component, OnDestroy, Output, EventEmitter } from '@angular/core';
import { delay, withLatestFrom, takeWhile } from 'rxjs/operators';
import {
  NbMediaBreakpoint,
  NbMediaBreakpointsService,
  NbMenuItem,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';
import { StateService } from '../../../@core/data/state.service';
import { environment } from 'environments/environment';

// TODO: move layouts into the framework
@Component({
  selector: 'ngx-sample-layout',
  styleUrls: ['./sample.layout.scss'],
  templateUrl: './zoffice.layout.html',
})
export class SampleLayoutComponent implements OnDestroy {
  @Output() selectedProduct: EventEmitter<any> = new EventEmitter();
  environment: any = environment;

  // subMenu: NbMenuItem[] = [
  //   {
  //     title: 'PAGE LEVEL MENU',
  //   },
  // ];

  quickMenu: NbMenuItem[] = [
    {
      title: 'Teachers',
      group: true
    },
    {
      title: 'New Teacher Order',
      icon: 'fas fa-plus',
      link: '/pages/teacher/order/new',
      data: {
        permission: 'create',
        resource: 'teacher'
      }
    },
    {
      title: 'View All Teachers',
      icon: 'fas fa-chalkboard-teacher',
      link: '/pages/teacher/directory',
      data: {
        permission: 'create',
        resource: 'teacher'
      }
    }
  ];

  layout: any = {};
  sidebar: any = {};

  private alive = true;

  currentTheme: string;

  constructor(protected stateService: StateService,
              protected menuService: NbMenuService,
              protected themeService: NbThemeService,
              protected bpService: NbMediaBreakpointsService,
              protected sidebarService: NbSidebarService) {
    this.stateService.onLayoutState()
      .pipe(takeWhile(() => this.alive))
      .subscribe((layout: string) => this.layout = layout);

    this.stateService.onSidebarState()
      .pipe(takeWhile(() => this.alive))
      .subscribe((sidebar: string) => {
        this.sidebar = sidebar;
      });

    const isBp = this.bpService.getByName('is');
    this.menuService.onItemSelect()
      .pipe(
        takeWhile(() => this.alive),
        withLatestFrom(this.themeService.onMediaQueryChange()),
        delay(20),
      )
      .subscribe(([item, [bpFrom, bpTo]]: [any, [NbMediaBreakpoint, NbMediaBreakpoint]]) => {
        if (bpTo.width <= isBp.width) {
          this.sidebarService.collapse('menu-sidebar');
        }
      });

    this.menuService.onItemClick()
      .subscribe(data => {
        switch (data.tag) {
          case 'quickMenu':
            this.sidebarService.collapse('quickmenu-sidebar');
            break;
        }
      });

    this.themeService.getJsTheme()
      .pipe(takeWhile(() => this.alive))
      .subscribe(theme => {
        this.currentTheme = theme.name;
    });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  // selectProduct(product: any) {
  //   this.selectedProduct.emit(product);
  // }
}
