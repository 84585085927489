import { Component, OnInit } from '@angular/core';
import { PrintService } from '@services/print.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TeacherOrderService } from '@services/teacher-order.service';
import { WarehouseService } from '@services/warehouse.service';
import { ConstantsService, Province } from '@core/data/constants';
import * as _ from 'lodash';

const PRODUCTS_COUNT = 38;

@Component({
  selector: 'ngx-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {
  type: string;
  item: string;
  ids: number[];
  action: string;
  orders: Array<any> = [];
  promises: Promise<any>[];
  showButtons = true;
  provinces: Array<Province> = [];

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _teacherOrderService: TeacherOrderService,
    private _warehouseService: WarehouseService,
    private _constants: ConstantsService,
    private _printService: PrintService
  ) {}

  ngOnInit() {
    this._route.params.subscribe(params => this._init(params));
    this._constants.getProvinces()
      .subscribe(provinces => this.provinces = provinces);
  }

  _init(params: any) {
    this.type = params['type'];
    this.item = params['item'];
    this.ids = params['ids'].split(',');

    if (this._route.snapshot.queryParams['action']) {
      this.action = this._route.snapshot.queryParams['action'];
    }

    switch (this.type) {
      case 'teacher':
        if (this.item === 'picklist' || this.item === 'packingslip') {
          this.promises = this.ids
            .map((id: number) => this._teacherOrderService.getOrderWithProducts(id));
        }

        if (this.item === 'boxLabels') {
          this.promises = this.ids
            .map((id: number) => this._teacherOrderService.getOrder(id));
        }

        if (this.promises) {
          Promise.all(this.promises)
            .then((orders: any) => {
              this.orders = [];
              _.forEach(orders, (o) => {
                if (o.products) {
                  const pages = _.chunk(o.products, PRODUCTS_COUNT);
                  _.forEach(pages, (products, idx) => {
                    this.orders.push({
                      coverSheet: (idx) ? false : true,
                      order: o.order,
                      products: products
                    });
                  });
                } else {
                  this.orders.push(o);
                }
              });

              if (this.action && this.action === 'print') {
                this._printService.onDataReady();
              }
            });
        }

        break;
    }
  }

  setToShipped() {
    this._warehouseService.setToShipped(this.ids);
  }

  goto(item: string) {
    const ids = this.ids.join(',');
    this._router.navigate(['/pages/warehouse/documents/', this.type, item, ids]);
  }

  print() {
    this._printService.printDocument('documents', this.type, this.item, this.ids);
  }

}
