// import { Teacher } from "../teacher";
import * as _ from 'lodash';

export interface OrderDate {
    year: number;
    release_date: string;
}

export class OrderDate implements OrderDate {
    constructor(data?: any) {
        if (data) {
            this.year = data.year;
            this.release_date = formatDate(data.release_date);
        } else {
            this.year = 2020;
            this.release_date = '';
        }
    }
}

export interface Taxes {
    gst: number;
    pst: number;
    hst: number;
    total: number;
}

export class Taxes {
    constructor(taxes?: any) {
        this.gst = (taxes) ? taxes.gst : 0;
        this.pst = (taxes) ? taxes.pst : 0;
        this.hst = (taxes) ? taxes.hst : 0;
        this.total = (taxes) ? taxes.total : 0;
    }
}

export interface Order {
    id: number;
    teacher: any;
    products: Array<any>;
    products_total: number;
    subtotal: number;
    discount: number;
    shipping: number;
    shipTo: string;
    shipDate: string;
    taxes: Taxes;
    total: number;
    status: string;
    invoice_status: string;
    charge_status: string;

    state: {
        name: string;
        accent: string;
    };
}

export class Order implements Order {
    constructor(order?: any) {
        this.status = 'New';
        this.products = [];
        this.subtotal = 0;
        this.total = 0;
        this.taxes = new Taxes();
        this.setState();

        if (order) {
            this.id = order.id;
            this.teacher = order.teacher;
            this.products = order.products;
            this.discount = order.discount;
            this.subtotal = order.subtotal;
            this.products_total = +order.products_total;
            this.shipping = order.shipping;
            this.shipTo = order.shipTo;
            this.shipDate = order.shipDate;
            this.invoice_status = order.invoice_status;

            // Taxes
            if (!order.taxes) {
                order.taxes = { gst: order.gst };
                if (order.pst) order.taxes.pst = order.pst;
                if (order.hst) order.taxes.hst = order.hst;

                order.taxes.total = 0;
                _.forEach(order.taxes, (value, key) => {
                    if (key !== 'total') {
                        order.taxes.total += +value;
                    }
                });
            }

            this.taxes = order.taxes;

            this.setState(order.status);
            this.setTotal();
        }
    }

    setState(state?: string) {
        switch (state) {
            case 'unsaved':
                this.state = { name: 'Unsaved', accent: 'warning' };
                break;

            case 'edit':
                this.state = { name: 'Editing', accent: 'info' };
                break;

            case 'Incomplete':
            case 'incomplete':
                this.state = { name: 'Incomplete', accent: 'warning' };
                break;

            case 'Backordered':
            case 'backordered':
                this.state = { name: 'Backordered', accent: 'warning' };
                break;

            case 'new':
            case 'New':
                this.state = { name: 'New', accent: 'primary' };
                break;

            case 'Shipped':
                this.state = { name: 'Shipped', accent: 'primary' };
                break;

            default:
                this.state = { name: 'Draft', accent: 'primary' };
                break;
        }
    }

    setTotal() {
        this.total = +this.subtotal + +this.shipping + +this.taxes.total;
    }
}


function formatDate(date) {
    const d = new Date(date);
    const year = d.getFullYear();
    let month = '' + (d.getMonth() + 1),
        day = '' + d.getDate();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}
