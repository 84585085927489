import { Injectable } from '@angular/core';
import { of as observableOf, Observable } from 'rxjs';

@Injectable()
export class InfoService {
    private _info: any = {
        address: { value: '1317 - 43 Avenue SE' },
        email: { value: 'info@schoolstart.ca' },
        phone: { value: '1-800-580-1868' },
        fax: { value: '1-800-580-1890' },
        tax: { value: 'GST/HST #826362055' }
    };

    private _infoArray: any[];

    constructor() {
        this._infoArray = Object.values(this._info);
    }

    getInfo(): Observable<any> {
        return observableOf(this._info);
    }

    getInfoArray(): Observable<any[]> {
        return observableOf(this._infoArray);
    }

    getInfoByKey(key: string): Observable<any> {
        return observableOf(this._info[key]);
    }
}
