import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { TeacherOrderService } from './teacher-order.service';
import { ErrorService } from '@core/data/error.service';
import { ConfirmComponent } from 'app/pages/miscellaneous/confirm/confirm.component';

@Injectable({
  providedIn: 'root'
})
export class WarehouseService {

  api = `${environment.api.endpoint}`;

  constructor(
    private _http: HttpClient,
    private _dialogService: NbDialogService,
    private _toastService: NbToastrService,
    private _teacherOrderService: TeacherOrderService,
    private _error: ErrorService) { }

  setToShipped(orders) {
    return new Promise((resolve, reject) => {
      this._dialogService.open(ConfirmComponent, {
        context: {
          title: 'Please Confirm',
          accent: 'warning',
          message: 'Are you sure you want to set the selected orders as Shipped?'
        }
      }).onClose
        .subscribe((yes: boolean) => {
          if (yes) {
            this._teacherOrderService.setToShipped(orders)
              .then(res => {
                this._toastService.success(res[0].message, 'Success');
                resolve();
              })
              .catch(err => {
                this._error.show('Setting to Shipped', err);
                reject();
              });
          }
        });
    });
  }

  fullfillBackorder(order: any): Promise<any> {
    // TODO: Fix this... (&& calc taxes etc.)
    order.total = 0;
    order.subtotal = 0;
    order.taxes = { gst: 0};
    order.parent_id = order.id;

    order.note = `Back Order Fullfilled for order #${order.id}`;

    // map backordered product
    order.products = order.products.map((product: any) => {
      product.id = product.product_id;
      product.quantity = product.backorder_qty;
      delete(product.product_id);
      return product;
    });

    return this._http.post(`${this.api}/TeacherOrders/add`, { data: order })
      .toPromise();
  }
}
