import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'ngx-packing-slip',
  templateUrl: './packing-slip.component.html',
  styleUrls: ['./packing-slip.component.scss']
})
export class PackingSlipComponent implements OnInit, OnChanges {
  @Input() data: any;
  order: any;
  products: Array<any> = [];
  contains_backorder = false;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    const data = changes.data.currentValue;
    this.order = data.order;

    _.forEach(data.products, (p: any) => {
      p.shipped = p.quantity;

      if (p.backorder && p.backorder === 'External') {
        p.shipped = p.quantity - p.backorder_qty;
        this.contains_backorder = true;
      }

      this.products.push(p);
    });
  }

}
