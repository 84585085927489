import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-columns',
  templateUrl: './columns.component.html',
  styleUrls: ['./columns.component.scss']
})
export class ColumnsComponent implements OnInit {
  @Input() columns: Array<any>;

  constructor(private _dialogRef: NbDialogRef<any>) { }

  ngOnInit() {
  }

  close() {
    this._dialogRef.close();
  }

}
