import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import { NbTokenService, NbAuthJWTToken } from '@nebular/auth';
import { map } from 'rxjs/operators';
import { NbToastrService } from '@nebular/theme';
import { environment } from '@environments/environment';

/**
 * TokenInterceptor
 * @see https://angular.io/guide/http#intercepting-all-requests-or-responses
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    // private authService: NbAuthService;
    // private tokenService: NbAuthJWTToken;

    constructor(
        private _tokenService: NbTokenService,
        private _toastService: NbToastrService
        ) {
    }

    // public getToken(): string {
    //     return localStorage.getItem('auth_app_token');
    // }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).do((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                // console.log(event); // Good Intercept
            }
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    // console.log('401', err);
                    const now = new Date();
                    this._tokenService.get()
                        .pipe(map((token: NbAuthJWTToken) => token.getTokenExpDate()))
                        .subscribe(expiry => {
                            if (expiry && expiry <= now) {
                                this._toastService.danger('Please Sign In Again', 'Sesssion Expired!', {
                                    duration: environment.toast.duration.error
                                });
                            }
                        });
                }
            }
        });

        // this.authService = this.injector.get(NbAuthService); // get it here within intercept

        // this.authService.isAuthenticated().subscribe((result) => {
        //     if (result) {
        //         // console.log('logged');
        //     }
        // });

        // return next.handle(request);
    }
}
