import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ConstantsService, Province } from '@core/data/constants';

@Component({
  selector: 'ngx-doc-header',
  templateUrl: './doc-header.component.html',
  styleUrls: ['./doc-header.component.scss']
})
export class DocHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() teacher: any;
  addressId: string;
  provinces: Array<Province> = [];

  constructor(private _constants: ConstantsService) { }

  ngOnChanges(changes: SimpleChanges) {
    const teacher = changes.teacher.currentValue;
    this.addressId = `s${teacher.school.address.id}`;
  }

  ngOnInit() {
    this._constants.getProvinces()
      .subscribe(provinces => this.provinces = provinces);
  }

}
