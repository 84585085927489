import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeacherOrdersComponent } from './teacher-orders/teacher-orders.component';
import { Ng2SmartTableModule } from 'ngx-smart-table';
import { ThemeModule } from '@theme/theme.module';
import { NgxPrintModule } from 'ngx-print';
import { DocumentsComponent } from './documents/documents.component';
import { PrintLayoutComponent } from './print-layout/print-layout.component';
import { RouterModule } from '@angular/router';
import { PicklistComponent } from './picklist/picklist.component';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { BoxLabelComponent } from './box-label/box-label.component';
import { PackingSlipComponent } from './packing-slip/packing-slip.component';
import { DocHeaderComponent } from './doc-header/doc-header.component';
import { CoverSheetComponent } from './cover-sheet/cover-sheet.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NbDatepickerModule } from '@nebular/theme';

const EXPORTS = [
  TeacherOrdersComponent,
  DocumentsComponent
];

const COMPONENTS = [
  PrintLayoutComponent,
  PicklistComponent,
  BoxLabelComponent,
  EXPORTS
];

@NgModule({
  declarations: [COMPONENTS, PackingSlipComponent, DocHeaderComponent, CoverSheetComponent],
  imports: [
    CommonModule,
    RouterModule,
    ThemeModule,
    NgxPrintModule,
    Ng2SmartTableModule,
    NgxDatatableModule,
    NgxBarcode6Module,
    NbDatepickerModule
  ],
  exports: [EXPORTS]
})
export class ComponentsModule { }
