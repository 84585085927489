import { Injectable } from '@angular/core';
// import { Observable } from 'rxjs';
// import { AngularFireAuth } from '@angular/fire/auth';
// import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { NbTokenService } from '@nebular/auth';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  api = `${environment.api.endpoint}`;

  constructor(
    private _http: HttpClient,
    private _tokenService: NbTokenService,
    // private _afAuth: AngularFireAuth,
    // private _afs: AngularFirestore
  ) {
    // this.user = this._afAuth.authState
    //   .pipe(
    //     switchMap(user => {
    //       if (user) {
    //         return this._afs.doc(`users/${user.uid}`)
    //           .valueChanges();
    //       } else {
    //         return of(null);
    //       }
    //     })
    //   );
  }

  async isAuthenticated(): Promise<boolean> {
    const token = await this.getAuthToken();
    return token.isValid();
  }

  async getAuthToken(): Promise<any> {
    return new Promise(resolve => {
      this._tokenService.get()
        .subscribe(token => resolve(token));
    });
  }

  async changePassword(data: { newPassword: string }, token?: string): Promise<any> {
    let header: any;

    if (token) { // temporary reset token
      header = { headers: new HttpHeaders().set('Authorization', token)};
    } else {
      header = await  this.getAuthorizatonHeader();
    }

    return this._http.post(`${this.api}/Staff/reset-password`, data, header)
      .toPromise();
  }

  async changeStaffPassword(data: { userId: number, password: string}): Promise<any> {
    return this._http.post(`${this.api}/Staff/changePassword`, { data: data })
      .toPromise();
  }

  async getAuthorizatonHeader(): Promise<any> {
    const token = await this.getAuthToken();
    const header = new HttpHeaders().set('Authorization', token.getValue());

    return { headers: header };
  }

  // getIdToken(): Promise<string> {
  //   return new Promise(resolve => {
  //     this._afAuth.idToken
  //       .subscribe(token => resolve(token));
  //   });
  // }

  // signIn(email: string, password: string): any {
  //   return this._afAuth.auth.signInWithEmailAndPassword(email, password)
  //     .then(credential => {
  //       const userRef: AngularFirestoreDocument<any> = this._afs.doc(`users/${credential.user.uid}`);

  //       userRef.valueChanges()
  //         .subscribe(user => {
  //           if (user !== undefined) {
  //             return user;
  //           } else {
  //             this._updateUserData(credential.user);
  //           }
  //         });
  //     });
  // }

  async signIn(data: {email: string, password: string}): Promise<any> {
    return this._http.post(`${this.api}/Staff/login`, data)
      .toPromise();
  }

  async signOut(): Promise<any> {

    // clear local storage
    localStorage.removeItem('auth_app_token');

    return this._tokenService.clear()
      .toPromise();

    // TODO: fix api logout authentication issue...
    const token = await this.getAuthToken();
    const headers = new HttpHeaders().set('Authorization', token.getValue());
    const url = `${this.api}/Staff/logout?access_token=${token.getValue()}`;
    return this._http.post(url, {}, { headers: headers })
      .toPromise()
      .then(() => this._tokenService.clear());

    // this._afAuth.auth.signOut()
    //   .then(_ => this._router.navigateByUrl('/'));
  }

  // private _updateUserData(user) {
  //   const userRef: AngularFirestoreDocument<any> = this._afs.doc(`users/${user.uid}`);

  //   const data: User = {
  //     uid: user.uid,
  //     email: user.email,
  //     displayName: user.displayName,
  //     photoURL: user.photoURL,
  //     role: { guest: true }
  //   };

  //   return userRef.set(data, { merge: true });
  // }
}
