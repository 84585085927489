import { Component, OnInit, Input } from '@angular/core';
import { NbSidebarService } from '@nebular/theme';

@Component({
  selector: 'ngx-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  @Input() history: Array<any> = [];

  constructor(private _sidebarService: NbSidebarService) { }

  ngOnInit() {
  }

  close() {
    this._sidebarService.toggle(false, 'history-sidebar');
  }

}
