import { Injectable } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ConfirmComponent } from 'app/pages/miscellaneous/confirm/confirm.component';

import * as _ from 'lodash';
import { WrkTeacherOrderService } from './wrk-teacher-order.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map, catchError } from 'rxjs/operators';
import { reject } from 'q';
import { throwError } from 'rxjs';

const YEAR = 2020; // TODO: move to config.

const TEACHER_INCLUDE = {
  relation: 'teacher',
  scope: {
    include: {
      relation: 'school',
      scope: {
        include: 'teacherOrderDates'
      }
    }
  }
};

const SCHOOL_WITH_OPTIONS = {
  relation: 'school',
  scope: {
    include: [
      {
        relation: 'schoolOptions',
        scope: {
          where: { year: YEAR }
        }
      },
      {
        relation: 'schoolDates',
        scope: {
          where: { year: YEAR }
        }
      }
    ]
  }
};

@Injectable({
  providedIn: 'root'
})
export class TeacherOrderService {

  api = `${environment.api.endpoint}`;

  constructor(
    private _dialogService: NbDialogService,
    private _http: HttpClient,
    private _wrkTeacherOrderService: WrkTeacherOrderService) { }

  async getOrder(id: number): Promise<any> {
    const filter = JSON.stringify({
      include: [TEACHER_INCLUDE]
    });

    return this._http
      .get(`${environment.api.endpoint}/TeacherOrders/${id}?filter=${filter}`)
      .toPromise();
  }

  async getKeyDates(schoolId: number, type?: string, year: number = YEAR) {
    const filter = {
      where: {
        year: year,
        school_id: schoolId
      }
    };

    if (type) {
      filter.where['type'] = type;
    }

    const f = JSON.stringify(filter);

    return this._http.get(`${this.api}/TeacherOrderDates?filter=${f}`)
      .toPromise();
  }

  async getOrderWithProducts(id: number) {
    return this._http.post(`${this.api}/TeacherOrders/products`, { id: +id })
      .toPromise();
  }

  async getOrders(): Promise<any> {
    const filter = JSON.stringify({
      include: TEACHER_INCLUDE,
      order: 'id DESC'
    });

    return this._http.get(`${this.api}/TeacherOrders?filter=${filter}`)
      .pipe(map((r: any) => {
        const orders: Array<any> = [];
        _.forEach(r, (o: any) => {
          orders.push({
            id: o.id,
            parent: o.parent_id,
            created: o.created,
            status: o.status,
            invoice_status: o.invoice_status,
            state: o.state,
            teacher: `${o.teacher.firstname} ${o.teacher.lastname}`,
            email: o.teacher.email,
            school: o.teacher.school.name,
            city: o.teacher.school.city,
            total: o.total,
            note: o.note,
            orderDates: _.find(o.teacher.school.teacherOrderDates, { year : YEAR })
          });
        });

        return orders;
      }),
      catchError(this._handleError))
      .toPromise();
  }

  _handleError(err: any) {
    console.error(err);
    return throwError(err);
  }

  async getOrderProducts(id: number, backorder: boolean = false): Promise<any> {
    const f = {
      where: { order_id: id },
      include: 'product'
    };

    if (backorder) {
      f.where['backorder'] = { neq: null };
    }

    const filter = JSON.stringify(f);

    return this._http.get(`${environment.api.endpoint}/TeacherOrderDetails?filter=${filter}`)
      .pipe(map((r: any) => {
        const products: Array<any> = [];
        r.forEach((p: any) => {
          products.push({
            id: p.id, // TeacherOrderDetail.id
            product_id: p.product.id,
            sku: p.product.sku,
            name: p.product.name,
            retail: p.product.retail,
            price: p.price,
            quantity: p.quantity,
            backorder: p.backorder,
            backorder_qty: p.backorder_qty
          });
        });

        return products;
      })).toPromise();
  }

  async saveOrder(order: any): Promise<any> {
    return new Promise((resolve, rejectSaveOrder) => {
      this._wrkTeacherOrderService.saveOrder(order)
        .then((wrkOrder: any) => {
          const TeacherOrders = this.getSavedOrders() || [];
          const idx = _.findIndex(TeacherOrders, { id: wrkOrder.id });

          if (idx !== -1) {
            TeacherOrders[idx] = order;
          } else {
            order.id = wrkOrder.id;
            TeacherOrders.push(order);
          }

          localStorage.setItem('teacher-orders', JSON.stringify(TeacherOrders));
          resolve(order);
        })
        .catch(err => rejectSaveOrder(err));
    });
  }

  async createOrder(data: any): Promise<any> {
    data.source = 'zOffice';
    data.created = new Date();
    data.updated = new Date();

    return Promise.all([
      this._http.post(`${this.api}/TeacherOrders/add`, { data: data })
        .toPromise(),
      this._removeDraft(data.id),
    ]);
  }

  getSavedOrders(): any {
    return JSON.parse(localStorage.getItem('teacher-orders'));
  }

  getSavedOrder(id: number) {
    const TeacherOrders = this.getSavedOrders() || {};
    return _.find(TeacherOrders, { id: +id });
  }

  async saveOrderProducts(order: any): Promise<any> {
    return Promise.all([
      this._updateProducts(order),
      this.updateOrder(order)
    ]);
  }

  async _updateProducts(order: any): Promise<any> {
    return this._http.post(`${this.api}/TeacherOrderDetails/updateProducts`, {data: order})
      .toPromise();
  }

  async updateOrderProduct(data: any): Promise<any> {
    return this._http.patch(`${this.api}/TeacherOrderDetails/${data.id}`, data)
      .toPromise();
  }

  async updateOrder(order: any): Promise<any> {
    return this._http.patch(`${this.api}/TeacherOrders/${order.id}`, order)
      .toPromise();
  }

  async cancelOrder(id: number): Promise<any> {
    return this._http.patch(`${this.api}/TeacherOrders/${id}`,
      { status: 'Cancelled', on_hold: 0 })
      .toPromise();
  }

  async _removeDraft(id: number): Promise<any> {
    return new Promise((resolve) => {
        let TeacherOrders = this.getSavedOrders() || {};
        _.remove(TeacherOrders, { id: +id });

        if (_.keys(TeacherOrders).length === 0) {
          TeacherOrders = null;
          localStorage.removeItem('teacher-orders');
        } else {
          localStorage.setItem('teacher-orders', JSON.stringify(TeacherOrders));
        }

        this._wrkTeacherOrderService.deleteOrder(id)
          .then(res => resolve(TeacherOrders));
    });
  }

  async discardOrder(id: number): Promise<any> {
    return new Promise((resolve, rejectDiscard) => {
      this._dialogService.open(ConfirmComponent, {
        context: {
          accent: 'warning',
          title: 'Please Confirm',
          message: 'Are you sure you want to discard this order?'
        }
      })
        .onClose
        .subscribe(yes => {
          if (yes) {
            this._removeDraft(id)
              .then(res => resolve(res))
              .catch(err => rejectDiscard(err));
          }
        });
    });
  }

  getOrdersByStatus(status: string, withSchoolOptons: boolean = false): Promise<any> {
    const include: any = TEACHER_INCLUDE;

    if (withSchoolOptons) {
      include.scope.include = SCHOOL_WITH_OPTIONS;
    }

    const filter = {
      where: { status: status },
      include: include,
      order: 'id DESC'
    };

    return new Promise((resolve, rejectFilteredByStatus) => {
      this._getFilteredOrders(JSON.stringify(filter))
        .then(res => resolve(res))
        .catch(err => rejectFilteredByStatus(err));
    });
  }

  getOrdersByState(state: string, f: string = null): Promise<any> {
    const filter = {
      where: { state: state },
      include: TEACHER_INCLUDE
    };

    if (f) { // filter order ids
      filter.where['id'] = { inq: f.split(',') };
    }

    return new Promise((resolve, rejectFilteredByState) => {
      this._getFilteredOrders(JSON.stringify(filter))
        .then(res => resolve(res))
        .catch(err => rejectFilteredByState(err));
    });
  }

  getBackorderedProducts(): Promise<any> {
    const filter = JSON.stringify({
      where: { backorder: { neq: null }},
      include: 'product'
    });

    return this._http.get(`${this.api}/TeacherOrderDetails?filter=${filter}`)
      .toPromise();
  }

  _getFilteredOrders(filter: string): Promise<any> {
    return this._http.get(`${this.api}/TeacherOrders?filter=${filter}`)
      .pipe(map((r: any) => {
        const orders: Array<any> = [];
        _.forEach(r, (o: any) => {
          orders.push({
            id: o.id,
            parent: o.parent_id,
            status: o.status,
            state: o.state,
            teacher: {
              id: o.teacher.id,
              name: `${o.teacher.firstname} ${o.teacher.lastname}`,
              school: {
                id: o.school_id,
                name: o.teacher.school.name
              }
            },
            email: o.teacher.email,
            city: o.teacher.school.city,
            orderDate: o.created,
            orderDates: _.find(o.teacher.school.teacherOrderDates, { year: YEAR })
          });
        });

        return orders;
      }))
      .toPromise();
  }

  // INCOMPLETE
  getIncompleteProducts(id: number): Promise<any> {
    const filter = JSON.stringify({
      where: { orderId: id },
      include: 'product'
    });

    return this._http.get(`${this.api}/TeacherOrderIncomplete?filter=${filter}`)
      .pipe(map((r: any) => {
        const products: Array<any> = [];
        _.forEach(r, (p: any) => {
          products.push({
            id: p.product.id,
            sku: p.product.sku,
            name: p.product.name,
            quantity: p.quantity,
            status: p.status
          });
        });

        return products;
      })).toPromise();
  }

  completeOrder(order: any): Promise<any> {
    return this._http.post(`${this.api}/TeacherOrders/complete`, {order: order})
      .toPromise();
  }

  createIncomplete(data: any): Promise<any> {
    const id = data.data.orderId;
    return Promise.all([
      this._addIncomplete(data),
      this.updateOrder({ id: id, state: 'Incomplete' }),
      this._logHistory({
        orderId: id,
        action: 'Incomplete',
        staffId: 1
      })
    ])
      .catch(err => reject(err));
  }

  setUnprocessed(data: Array<number>): Promise<any> {
    const history: Array<any> = [];
    _.forEach(data, (item: any) => {
      history.push({
        action: 'Set Unprocessed',
        orderId: item.id
      });
    });

    return Promise.all([
      this._unprocessed(data),
      this._logHistory(history)
    ])
      .catch(err => reject(err));
  }

  setOnDeck(data: Array<number>): Promise<any> {
    const history: Array<any> = [];
    _.forEach(data, (item: any) => {
      history.push({
        action: 'On Deck',
        orderId: item.id
      });
    });

    return Promise.all([
      this._onDeck(data),
      this._logHistory(history)
    ])
      .catch(err => reject(err));
  }

  setToShipped(ids: Array<string>): Promise<any> {
    const history: Array<any> = [];
    _.forEach(ids, (id: string) => {
      history.push({
        action: 'Shipped',
        orderId: id
      });
    });

    return Promise.all([
      this._shipped(ids),
      this._logHistory(history)
    ])
      .catch(err => reject(err));
  }

  updateTeacherOrderDates(data: any): Promise<any> {
    return this._http.post(`${this.api}/TeacherOrderDates/updateDates`, { data: data })
      .toPromise();
  }

  getHistory(id: number): Promise<any> {
    const filter = JSON.stringify({
      where: { orderId: id },
      include: 'staff'
    });

    return this._http.get(`${this.api}/TeacherOrderHistory?filter=${filter}`)
      .toPromise();
  }

  _logHistory(data: any): Promise<any> {
    return this._http.post(`${this.api}/TeacherOrderHistory/log`, {
      data: (data instanceof Array) ? data : [data]
    })
      .toPromise();
  }

  _unprocessed(data: Array<any>): Promise<any> {
    return this._http.post(`${this.api}/TeacherOrders/unprocessed`, {
      orders: _.map(data, 'id') }).toPromise();
  }

  _onDeck(data: Array<any>): Promise<any> {
    return this._http.post(`${this.api}/TeacherOrders/onDeck`, {
      orders: _.map(data, 'id') }).toPromise();
  }

  _shipped(data: Array<string>): Promise<any> {
    return this._http.post(`${this.api}/TeacherOrders/shipped`, { orders: data })
      .toPromise();
  }

  _addIncomplete(data: any): Promise<any> {
    return this._http.post(`${this.api}/TeacherOrderIncomplete/add`, data)
      .toPromise();
  }

}
