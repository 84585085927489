import { Component, ChangeDetectorRef, Inject, OnInit } from '@angular/core';
import { NbResetPasswordComponent, NbAuthService, NB_AUTH_OPTIONS } from '@nebular/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';

import * as _ from 'lodash';

@Component({
  selector: 'ngx-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent extends NbResetPasswordComponent implements OnInit {
  accessToken: string;

  constructor(
    @Inject(NB_AUTH_OPTIONS) protected _options,
    private _auth: NbAuthService,
    private _router: Router,
    private _cd: ChangeDetectorRef,
    private _authService: AuthService,
    private _route: ActivatedRoute) {
    super(_auth, _options, _cd, _router);

    this._auth;
    this._cd;
  }

  ngOnInit() {
    this.accessToken = this._route.snapshot.queryParamMap.get('access_token');
  }

  resetPass() {
    this._authService.changePassword({ newPassword: this.user.password }, this.accessToken)
      .then(() => {
        this._router.navigate(['/auth/login']);
      })
      .catch(err => this.errors = [err.statusText]);
  }

}
