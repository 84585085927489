import { HttpErrorResponse } from '@angular/common/http';

export interface Error {
    code: string;
    title: string;
    message: string;
}

export class Error implements Error {
    constructor(title: string, response: any) {
        console.error(response);
        this.title = `Error: ${title}`;

        if (response instanceof HttpErrorResponse) {
            this.message = response.error.error.message;
        } else {
            this.message = response.message;
        }
    }
}
