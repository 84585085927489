import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'ngx-picklist',
  templateUrl: './picklist.component.html',
  styleUrls: ['./picklist.component.scss']
})
export class PicklistComponent implements OnInit, OnChanges {
  @Input() data: any;
  order: any;
  products: Array<any> = [];


  constructor() {}

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    const data = changes.data.currentValue;
    this.order = data.order;

    _.forEach(data.products, (p: any) => {
      p.shipped = p.quantity;

      if (p.backorder) {
        if (!p.backorder_qty) {
          p.shipped = 0;
        } else {
          p.shipped = p.quantity - p.backorder_qty;
        }
      }

      if (p.shipped > 0) {
        this.products.push(p);
      }
    });
  }

}
