import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'ngx-box-label',
  templateUrl: './box-label.component.html',
  styleUrls: ['./box-label.component.scss']
})
export class BoxLabelComponent implements OnInit, OnChanges {
  @Input() order: any;
  @Input() provinces: Array<any>;
  settings: any = {
    rows: 7,
    cols: 2
  };

  labels: Array<any>;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
        // const order = this.order;
    const order = changes.order.currentValue;
    const page = this.settings.rows * this.settings.cols;

    this.labels = [];
    this.labels = _.fill(Array(page), order.teacher);
  }

  ngOnInit() {
  }
}
