import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { NbToastrService } from '@nebular/theme';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  api = `${environment.api.endpoint}`;

  constructor(
    private _toastService: NbToastrService,
    private _http: HttpClient) { }

  async getCategories(type: string = 'product'): Promise<any> {
    const end = (type === 'product') ? 'product_categories' : 'StoreCategories';
    const filter = JSON.stringify({
      order: 'name ASC'
    });

    return this._http.get(`${this.api}/${end}?filter=${filter}`)
      .toPromise()
      .catch(err => {
        console.error(err);
        this._toastService.danger(err.statusText, 'Error fetching product categories',
          { duration: environment.toast.duration.error });
      });
  }

  async getCategoryProducts(id: number): Promise<any> {
    const filter = JSON.stringify({
      include: {
        relation: 'products',
        scope: {
          include: 'supplier'
        }
      }
    });

    return this._http.get(`${this.api}/product_categories/${id}?filter=${filter}`)
    .toPromise();
  }

  async deleteCategory(id: number): Promise<any> { }

  async getProduct(id: number): Promise<any> {
    const filter = JSON.stringify({
      include: ['supplier', 'productCategory', 'storeCategory']
    });

    return this._http.get(`${this.api}/products/${id}?filter=${filter}`)
      .toPromise();
  }

  async getProducts(): Promise<any> {
    const filter = JSON.stringify({
      include: 'supplier'
    });

    return this._http.get(`${this.api}/products?filter=${filter}`)
      .toPromise()
      .catch(err => {
        console.error(err);
        this._toastService.danger(err.statusText, 'Error fetching products',
          { duration: environment.toast.duration.error });
      });
  }

  async updateProduct(data: any): Promise<any> {
    return this._http.patch(`${this.api}/products/${data.id}`, data)
      .toPromise();
  }
}
