import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

@Component({
  // selector: 'logout',
  // templateUrl: './logout.component.html',
  // styleUrls: ['./logout.component.scss']
  selector: 'ngx-logout',
  template: `Loggin Out...`
})
export class LogoutComponent implements OnInit {

  constructor(
    private _authService: AuthService,
    private _router: Router
  ) { }

  ngOnInit() {
    this._authService.signOut()
      .then(() => this._router.navigateByUrl('/'));
  }

}
