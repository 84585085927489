import { Component, Input, OnInit } from '@angular/core';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
// import { AnalyticsService } from '@core/utils/analytics.service';
import { Staff } from '@core/user';
import { NbTokenService, NbAuthJWTToken } from '@nebular/auth';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  @Input() position = 'normal';

  user: Staff;

  userMenu = [
    { title: 'Profile', link: '/pages/user/profile' },
    { title: 'Log out', link: '/auth/logout' }
  ];

  settingsMenu = [
    { title: 'Info', link: '/pages/misc/info' }
  ];

  searchMenu = [
    { title: 'School Search', data: { search: { type: 'modal-zoomin', tag: 'school'}} }
  ];

  toolsMenu = [
    { title: 'Staff Members', icon: 'fas fa-user-cog', link: '/pages/misc/staff' },
    { title: 'Info', icon: 'fas fa-info', link: '/pages/misc/info' }
  ];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              // private analyticsService: AnalyticsService,
              private _tokenService: NbTokenService) {
  }

  ngOnInit() {
    this._tokenService.get()
      .pipe(map((token: NbAuthJWTToken) => token.getPayload()))
      .subscribe(token => {
        this.user = new Staff(token.user);
      });
  }

  toggleSidebar(compact: boolean, tag: string): boolean {
    this.sidebarService.toggle(compact, tag);

    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  // startSearch() {
  //   this.analyticsService.trackEvent('startSearch');
  // }
}
