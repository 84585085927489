import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Display } from './display';

@Injectable()
export class DisplayService {
  currentDisplay: Subject<Display> = new Subject();

  constructor() { }

  show(display: Display) {
    this.currentDisplay.next(display);
  }
}
