import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Error } from './error';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  options = {
    duration: environment.toast.duration.error
  };

  constructor(private _toastService: NbToastrService) { }

  show(title: string, response: any) {
    const error = new Error(title, response);
    this._toastService.danger(error.message, error.title, this.options);
  }
}
