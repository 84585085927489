import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private _nbAuthService: NbAuthService,
    private _authService: AuthService,
    private _router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {

      return this._nbAuthService.isAuthenticated()
        .pipe(
          tap(authenticated => {
            if (!authenticated) {
              this._authService.signOut();
              this._router.navigate(['auth/login']);
            }
          })
        );
  }
}
