import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { Routes, RouterModule } from '@angular/router';
import { NbAuthComponent, NbAuthModule } from '@nebular/auth';
import { FormsModule } from '@angular/forms';
import { ThemeModule } from '@theme/theme.module';
import { ResetComponent } from './reset/reset.component';

const routes: Routes = [
  {
    path: '',
    component: NbAuthComponent,
    children: [
      { path: 'logout', component: LogoutComponent },
      { path: 'reset-password', component: ResetComponent }
    ]
  }
];

@NgModule({
  declarations: [LoginComponent, LogoutComponent, ResetComponent],
  imports: [
    CommonModule,
    NbAuthModule,
    FormsModule,
    ThemeModule,
    RouterModule.forChild(routes)
  ]
})
export class AuthModule { }
